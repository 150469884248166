import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';

import { receiveProducts } from '@ttstr/actions';
import { Container, LoadingSpinner, Masonry, useIntl } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import { Article } from '@ttstr/api';
import FilteredMasonry from './FilteredMasonry';

type Props = {
  /**
   * productFilter can be used if you want a Route to a dedicated page for special merch/products.
   *
   * Example:
   * You want a page only with merch that has the tag id 1234.
   * The function for productFilter would then be
   *  p => p.tag_ids.includes(1234)
   *
   * Then create a route in App.tsx with
   * <Route exact path="/specialmerch" render={()=> <ProductListing productFilter={p => p.tag_ids.includes(1234)} />} />
   */
  productFilter?: (p: Article) => any;
};

const ProductListing: React.FC<Props> = ({ productFilter }) => {
  const { t } = useTranslation();
  const { loading, products } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveProducts();
  }, [language]);

  return (
    <article>
      {/* <Container fluid className="header-image" /> */}
      <Container className="list-page mb-5">
        <Helmet>
          <title>{t(`PRODUCTS.TITLE`)}</title>
          <body className="product-listing-page" />
        </Helmet>

        {/* <div className="text-center">
          <img src={require('./assets/images/home-logo.svg')} alt="" className="img-fluid w-50 my-5" />
        </div> */}

        {/* set empty h1 with height as spacer */}
        <h1 className="superhead mt-5 mb-3">MERCHANDISE</h1>

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : products.length === 0 ? ( // if no products
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show products
          <Masonry
            id="product-listing"
            products={products.filter(productFilter)}
            columnClassName="col-sm-6 col-md-4 col-lg-4"
            // filters={['fulltext', 'brandId']}
            showFormerPrice
            showSubtitle
          />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(ProductListing);
